<template>
  <div>
    <v-container
      fluid
    >
      <v-row
        class="overflowLayout"
      >
        <v-col
          class="overflowFlex"
        >
          <v-data-table
            style="white-space: nowrap;"
            dense
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :footer-props="footerProps"
            :server-items-length="totalItems"
            item-key="productId"
            @update:options="updateQuery()"
            fixed-header
            height="calc(100vh - 150px)"
            mobile-breakpoint="0"
            :value="selected"
            single-select
          >
              <template
                v-slot:[`body.prepend`]="{ headers }"
              >
              <tr
                class="tableFilter"
                py-3
              >
                <td v-for="(header,key) in headers" :key="key" class="pb-1">
                  <v-text-field
                    v-if="header.filterType === 'text'"
                    v-model="header.filterValue"
                    hide-details
                    single-line
                    dense
                    clearable
                    @keyup.esc="header.filterValue = ''"
                  ></v-text-field>
                  <v-select
                    v-else-if="header.filterType === 'select'"
                    flat
                    hide-details
                    dense
                    v-model="header.filterValue"
                    :items="header.selectList"
                  ></v-select>
                </td>
              </tr>
            </template>
            <template
              v-slot:item="{ item, index }"
            >
              <tr
                :key="item.productId"
                @click.stop="showItem(item)"
                :class="(index === selectedIndex) ? 'v-data-table__selected' : ''"
              >
                <td>{{ item.productId }}</td>
                <td>{{ item.priceList }}</td>
                <td>{{ item.family }}</td>
                <td>{{ item.origin }}</td>
                <td>{{ item.toast }}</td>
                <td class="text-right">{{ item.saleListPrice | toRounded(2) }}</td>
                <td>{{ item.udsKg }}</td>
                <td class="text-right">{{ item.stockQty }}</td>
                <td class="text-right">{{ item.salesQtyOrdered }}</td>
                <td class="text-right">{{ item.qtyAvailable2 }}</td>
                <td class="text-right">{{ item.entryQtyFuture }}</td>
                <td class="text-right">{{ item.qtyAvailable3 }}</td>
                <td class="text-right">{{ item.salesQtyOffered }}</td>
                <td class="text-right">{{ item.salesQty365Days }}</td>
              </tr>
            </template>

            <template v-slot:[`footer.prepend`]>
              <v-btn
                v-if="userCanEdit"
                @click="createItem ()"
                title="Crear producto (Ctrl. + Ins.)"
                small
                fab
                color="primary"
                elevation="2"
              >
                <v-icon>{{ mdiPlus }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <ModalProduct
      v-model="modalIsOpen"
      :product="modalProduct"
    >
    </ModalProduct>

  </div>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'
import ModalProduct from '@/components/ModalProduct'

export default {
  mixins: [ItemsMixins],
  components: {
    ModalProduct
  },
  data () {
    return {
      editRight: 'CAN_PURCHASES_WRITE',
      itemsName: 'products',
      itemName: 'product',
      modalIsOpen: false,
      modalProduct: {},
      headers: [
        {
          text: 'Referencia',
          value: 'productId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'LP',
          value: 'priceList',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('priceListSelect'),
          filterSearch: 'full'
        },
        {
          text: 'Familia',
          value: 'family',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('familySelect'),
          filterSearch: 'full'
        },
        {
          text: 'Origen',
          value: 'origin',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('originSelect'),
          filterSearch: 'full'
        },
        {
          text: 'Tostado',
          value: 'toast',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('toastSelect'),
          filterSearch: 'full'
        },
        /*
        {
          text: 'Nombre',
          value: 'productName',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        */
        {
          text: 'Precio',
          value: 'saleListPrice',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'UdsKg',
          value: 'udsKg',
          filterType: 'select',
          filterValue: '',
          selectList: [{ text: 'All', value: '' }, 'Kg', 'Uds'],
          filterSearch: 'full'
        },
        {
          text: 'Stock',
          value: 'stockQty',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'PEs',
          value: 'entryQtyFuture',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Disp2',
          value: 'qtyAvailable2',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Entradas',
          value: 'entryQtyFuture',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Disp3',
          value: 'qtyAvailable3',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'COs',
          value: 'salesQtyOffered',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Ventas365',
          value: 'salesQty365Days',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        }
      ]
    }
  },
  methods: {
    defaultOptions () {
      return {
        sortBy: ['productId'], sortDesc: [false], itemsPerPage: this.savedItemsPerPage(10), page: 1
      }
    },
    showItem (product) {
      console.log('showItem')
      this.modalIsOpen = true
      this.modalProduct = product
    },
    closeModal () {
      this.modalIsOpen = false
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .tableFilter .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
      border-color: #c0970e;
  }
  .tableFilter .theme--light.v-select .v-select__selections {
      color: #c0970e;
  }
  .tableFilter .theme--light.v-select .v-input__icon .v-icon {
      color: #c0970e;
  }
</style>
