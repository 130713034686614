<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        flat
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            pa-2
          >
            <v-row
              dense
            >
              <v-col
                cols="6"
                sm="2"
              >
                <v-text-field
                  dense
                  :disabled="!item.isNew"
                  persistent-placeholder
                  filled
                  :ref="item.isNew ? 'focussedInput' : ''"
                  type="text"
                  maxlength=12
                  v-model="item.productId"
                  :rules="requiredMax12Rules"
                  label="Referencia"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                sm="2"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=12
                  v-model="item.genericCode"
                  :rules="requiredMax12Rules"
                  label="Referencia genérica antigua"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="8"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  :ref="!item.isNew ? 'focussedInput' : ''"
                  type="text"
                  maxlength=80
                  v-model="item.productName"
                  :rules="requiredRules"
                  label="Nombre Producto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              dense
            >
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=16
                  v-model="item.supplierProductId"
                  label="Ref. Proveedor"
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="4"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=80
                  v-model="item.supplierProductName"
                  label="Nombre Producto Proveedor"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  persistent-placeholder
                  filled
                  label="Familia"
                  v-model="item.family"
                  :rules="requiredRules"
                  :items="familySelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>

              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  persistent-placeholder
                  filled
                  label="Origen"
                  v-model="item.origin"
                  :rules="requiredRules"
                  :items="originSelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>

              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  persistent-placeholder
                  filled
                  label="Tostado"
                  v-model="item.toast"
                  :rules="requiredRules"
                  :items="toastSelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>

              </v-col>
            </v-row>

            <v-row
              dense
            >

              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="number"
                  v-model="item.weight"
                  :rules="positiveDecimalRules"
                  label="Peso"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  persistent-placeholder
                  filled
                  v-model="item.showWeight"
                  label="UdsKg"
                  :items="[{text: 'Kg.', value: 1}, {text: 'Uds.', value: 0}]"
                >
                </v-select>
              </v-col>

              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="number"
                  v-model="item.palletSize"
                  :rules="positiveIntegerRules"
                  label="Tamaño Palet (Unidades!)"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="number"
                  v-model="item.purchaseListPrice"
                  :rules="positiveDecimalRules"
                  label="Precio Coste Lista"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="number"
                  v-model="item.saleListPrice"
                  :rules="positiveDecimalRules"
                  label="Precio Lista"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  persistent-placeholder
                  filled
                  type="number"
                  v-model="item.minStock"
                  :rules="positiveIntegerRules"
                  label="Stock mínimo"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  persistent-placeholder
                  filled
                  v-model="item.priceList"
                  :rules="requiredRules"
                  label="Lista Precios"
                  :items="priceListSelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  persistent-placeholder
                  filled
                  v-model="item.priceGroupId"
                  :rules="requiredRules"
                  label="Grupo Descuento"
                  :items="priceGroups"
                  item-text="priceGroupName"
                  item-value="priceGroupId"
                >
                </v-select>
              </v-col>
            </v-row>

          </v-container>
          <!-- footer -->
          <div
            class="text-caption"
          >
            <span v-if="item.productId === 'new_'">Pendiente de guardar.</span>
            <span v-else>
              Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
            </span>
            <span v-if="item.recChanger">
              Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
            </span>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!item.isNew && userCanEdit"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!item.isNew && userCanEdit"
            @click="copyItem"
            text
            title="Copiar y usar como modelo para nuevo elemento"
          >
            Copiar
          </v-btn>
          <v-btn
            @click="close"
            text
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import ItemMixins from '@/utils/ItemMixins'

export default {
  mixins: [ItemMixins],
  data () {
    return {
      editRight: 'CAN_PURCHASES_WRITE',
      itemName: 'product', // needed for ItemMixins
      itemsName: 'products', // needed for ItemMixins
      editMode: true, // always edit mode
      priceListSelect: this.$store.state.priceListSelect,
      priceGroups: this.$store.state.priceGroups,
      originSelect: this.$store.state.originSelect,
      familySelect: this.$store.state.familySelect,
      toastSelect: this.$store.state.toastSelect,
      requiredMax12Rules: [
        (v) => !!v || '¡Información requerida',
        (v) => (v && v.length <= 12) || 'Longitud máxima 12 caracteres'
      ],
      requiredRules: [
        (v) => !!v || '¡Información requerida'

      ],
      positiveDecimalRules: [
        // positive decimal
        (v) => /^\d*\.{0,2}\d+$/.test(v) || 'Se requiere un número'
      ],
      positiveIntegerRules: [
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Se requiere un número (integro)'
      ]
    }
  },
  methods: {
    copyItem () {
      this.createItem(this.item)
    },
    createItem (copyFrom) {
      if (copyFrom) {
        this.item = Object.assign({}, copyFrom)
        this.item.isNew = true
        this.item.productId = ''
        this.item.genericCode = ''
        // replace path without rendering
        window.history.replaceState({}, null, '/#/' + this.itemsName + '/new_')
      } else {
        this.item = {
          isNew: true,
          productId: '',
          genericCode: '',
          productName: '',
          family: '',
          origin: '',
          toast: '',
          supplierProductId: '',
          supplierProductName: '',
          weight: 0,
          showWeight: 0,
          palletSize: 0,
          purchaseListPrice: 0,
          saleListPrice: 0,
          minStock: 0,
          priceList: '',
          priceGroupId: ''
        }
      }

      this.editMode = true
    }
  }
}
</script>
