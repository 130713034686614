<template>
  <v-container
    fluid
  >
    <v-row
      class="overflowLayout"
    >
      <v-col
        class="overflowFlex"
      >
        <v-data-table
          style="white-space: nowrap;"
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :footer-props="footerProps"
          :server-items-length="totalItems"
          @update:options="updateQuery()"
          fixed-header
          height="calc(100vh - 130px)"
          mobile-breakpoint="0"
          :value="selected"
          single-select
        >
            <template
              v-slot:[`body.prepend`]="{ headers }"
            >
            <tr
              class="tableFilter"
              py-3
            >
              <td v-for="(header,key) in headers" :key="key" class="pb-1">
                <v-text-field
                  v-if="header.filterType === 'text'"
                  v-model="header.filterValue"
                  hide-details
                  single-line
                  dense
                  clearable
                  @keyup.esc="header.filterValue = ''"
                ></v-text-field>
                <v-select
                  v-else-if="header.filterType === 'select'"
                  flat
                  hide-details
                  dense
                  v-model="header.filterValue"
                  :items="header.selectList"
                ></v-select>
              </td>
            </tr>
          </template>
          <template
            v-slot:item="{ item }"
          >
            <tr
              :key="item.id"
            >
              <td>{{ item.productId }}</td>
              <td> {{ item.priceList }} </td>
              <td> {{ item.family }} </td>
              <td> {{ item.origin }} </td>
              <td> {{ item.toast }} </td>
              <td> {{ item.productName }} </td>
              <td> {{ item.udsKg }} </td>
              <td> {{ item.palletId }} </td>
              <td> {{ (item.receptDate || '').substring(0,10) }} </td>
              <td class="text-right"> {{ item.entryQty }} </td>
              <td class="text-right"> {{ item.salesQty }} </td>
              <td class="text-right"> {{ item.stockQty }} </td>
              <td class="text-right"> {{ item.stockValue | toRounded(2) }} </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'

export default {
  mixins: [ItemsMixins],
  data () {
    return {
      itemsName: 'stockdetails',
      itemName: 'stockdetail',
      headers: [
        {
          text: 'Referencia',
          value: 'productId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'LP',
          value: 'priceList',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('priceListSelect'),
          filterSearch: 'full'
        },
        {
          text: 'Familia',
          value: 'family',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('familySelect'),
          filterSearch: 'full'
        },
        {
          text: 'Origen',
          value: 'origin',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('originSelect'),
          filterSearch: 'full'
        },
        {
          text: 'Tostado',
          value: 'toast',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('toastSelect'),
          filterSearch: 'full'
        },
        {
          text: 'Nombre',
          value: 'productName',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'UdsKg',
          value: 'udsKg',
          filterType: 'select',
          filterValue: '',
          selectList: [{ text: 'All', value: '' }, 'Kg', 'Uds'],
          filterSearch: 'full'
        },
        {
          text: 'Palet Id',
          value: 'palletId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'F. recepción',
          value: 'receptDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Entradas',
          value: 'entryQty',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Ventas',
          value: 'salesQty',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },

        {
          text: 'Stock',
          value: 'stockQty',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Valor',
          value: 'stockValue',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        }
      ]
    }
  },
  methods: {
    showItem () {
      // do nothing
    },
    defaultOptions () {
      return {
        sortBy: ['productId'], sortDesc: [false], itemsPerPage: this.savedItemsPerPage(10), page: 1
      }
    }
  }
}
</script>
