<template>
    <v-dialog
      v-model="show"
      scrollable
    >
      <v-card
      >
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="cancel"
          >
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ product.productId }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              v-if="user.rights & availablePermissions['CAN_PURCHASES_WRITE']"
              dark
              text
              @click="editProduct"
              title="Editar (F2)"
            >
              Editar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>
          {{ product.productName }}
        </v-card-title>
        <v-card-text
        >

          <v-container
            fluid
          >
              <v-row
                dense
              >
                <v-col
                  cols="12"
                >

                </v-col>
                <v-col
                  cols="12"
                  xl="3"
                  sm="6"
                >
                  <v-simple-table
                    class="productTable"
                    dense
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            colspan="2"
                          >
                            Detalles
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-right">Lista Precios</td>
                          <td>{{ product.priceList }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Familia</td>
                          <td>{{ product.family }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Origen</td>
                          <td>{{ product.origin }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Tostado</td>
                          <td>{{ product.toast }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Unidad de Venta</td>
                          <td>{{ product.showWeight === 0 ? '1 Ud' : product.weight + ' Kg' }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Uds por palet</td>
                          <td>{{ product.showWeight === 0 ? product.palletSize + ' Uds' : product.palletSize * product.weight + ' Kg' }}</td>
                        </tr>
                        <tr
                          v-if="product.showWeight === 0"
                        >
                          <td class="text-right">Peso por Unidad</td>
                          <td>{{ product.weight }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Precio Lista</td>
                          <td>{{ product.saleListPrice | toRounded(2) }}</td>
                        </tr>
                        <tr
                          v-if="user.rights & availablePermissions['CAN_PURCHASES_READ']"
                        >
                          <td class="text-right">Precio Coste Lista</td>
                          <td>{{ product.purchaseListPrice | toRounded(2) }}</td>
                        </tr>
                        <tr
                          v-if="product.showWeight === 1 && !(user.rights & availablePermissions['CAN_PURCHASES_READ'])"
                        >
                          <td class="text-right"></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </v-col>
                <v-col
                  cols="12"
                  xl="3"
                  sm="6"
                >
                  <v-simple-table
                    class="productTable"
                    dense
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            colspan="2"
                          >
                            Stock
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-right">Stock</td>
                          <td>{{ product.stockQty + ' ' + product.udsKg }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">PI/ALs</td>
                          <td>{{ product.qtyProcessing + ' ' + product.udsKg }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Disp1</td>
                          <td>{{ product.qtyAvailable + ' ' + product.udsKg }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">PEs</td>
                          <td>{{ product.salesQtyOrdered + ' ' + product.udsKg }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Disp2</td>
                          <td>{{ product.qtyAvailable2 + ' ' + product.udsKg }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Entradas</td>
                          <td>{{ product.entryQtyFuture + ' ' + product.udsKg }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">Disp3</td>
                          <td>{{ product.qtyAvailable3 + ' ' + product.udsKg }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">COs</td>
                          <td>{{ product.salesQtyOffered + ' ' + product.udsKg }}</td>
                        </tr>
                        <tr
                          v-if="product.showWeight === 0 && (user.rights & availablePermissions['CAN_PURCHASES_READ'])"
                        >
                          <td class="text-right"></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col
                  cols="12"
                  xl="6"
                >
                  <v-simple-table
                    class="productTable"
                    dense
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            colspan="9"
                          >
                            Tabla de descuento para cantidad: {{ productPriceGroup.priceGroupName }}
                          </th>
                        </tr>
                        <tr>
                          <th>
                            Cantidad
                          </th>
                          <th>
                            Precio Rango
                          </th>
                          <th>
                            Dto PR
                          </th>
                          <th
                            v-if="user.rights & availablePermissions['CAN_PURCHASES_READ']"
                          >
                            Margen PR
                          </th>
                          <th>
                            Comisión PR
                          </th>
                          <th>
                            Precio Minimo
                          </th>
                          <th>
                            Dto PM
                          </th>
                          <th
                            v-if="user.rights & availablePermissions['CAN_PURCHASES_READ']"
                          >
                            Margen PM
                          </th>
                          <th>
                            Comisión PM
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in productPriceGroupLevels"
                          :key="item.levelId"
                        >
                          <td>> {{ item.minQty + ' ' + product.udsKg }}</td>
                          <td class="text-right">{{ item.levelPrice | toRounded(2) }}</td>
                          <td class="text-right">{{ item.levelDiscount * 100 | toRounded(1) }}%</td>
                          <td
                            v-if="user.rights & availablePermissions['CAN_PURCHASES_READ']"
                            class="text-right"
                          >
                            {{ item.levelMargin * 100 | toRounded(1) }}%
                          </td>
                          <td class="text-right">{{ item.levelCommision * 100 | toRounded(1) }}%</td>
                          <td class="text-right">{{ item.levelMaxPrice | toRounded(2) }}</td>
                          <td class="text-right">{{ item.levelMaxDiscount * 100 | toRounded(1) }}%</td>
                          <td
                            v-if="user.rights & availablePermissions['CAN_PURCHASES_READ']"
                            class="text-right"
                          >
                            {{ item.levelMaxMargin * 100 | toRounded(1) }}%
                          </td>
                          <td class="text-right">{{ item.levelMaxCommision * 100 | toRounded(1) }}%</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
          </v-container>

        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import EventBus from '@/components/EventBus'

export default {
  data () {
    return {
      mdiClose: mdiClose,
      user: this.$store.state.user,
      availablePermissions: this.$store.state.availablePermissions
    }
  },
  props: {
    // bidirectional for dlg open status
    value: Boolean,
    product: Object
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    productPriceGroup () {
      const lookup = []
      const priceGroups = this.$store.state.priceGroups
      for (const item of priceGroups) {
        lookup[item.priceGroupId] = item
      }
      return lookup[this.product.priceGroupId]
    },
    productPriceGroupLevels () {
      // calculate pricegroup levels like in q_price_group_levels
      const discounts = {} // lookup
      const priceGroupLevels = this.$store.state.priceGroupLevels
      const levels = []
      const pg = this.productPriceGroup

      // make lookup
      for (const item of priceGroupLevels) {
        if (item.priceGroupId === this.product.priceGroupId) {
          discounts[item.levelId] = {
            levelId: item.levelId,
            discount: item.discount,
            minQty: item.minQty,
            maxQty: item.maxQty
          }
        }
      }

      for (const key in discounts) {
        const item = discounts[key]

        const levelId = item.levelId
        const levelDiscount = item.discount
        const levelPrice = (1 - levelDiscount) * this.product.saleListPrice
        const levelCommision = (pg.commision - 0.25 * levelDiscount)

        const levelMax = item.levelId + pg.extraDiscountLevels
        const levelMaxDiscount = (discounts[levelMax] && (discounts[levelMax].discount < pg.maxDiscount)) ? discounts[levelMax].discount : pg.maxDiscount
        const levelMaxPrice = (1 - levelMaxDiscount) * this.product.saleListPrice
        const levelMaxCommision = levelCommision - 0.5 * (levelMaxDiscount - levelDiscount)

        const level = {
          minQty: item.minQty,

          levelId: levelId,
          levelDiscount: levelDiscount,
          levelPrice: levelPrice,
          levelCommision: levelCommision,

          levelMax: levelMax,
          levelMaxDiscount: levelMaxDiscount,
          levelMaxPrice: levelMaxPrice,
          levelMaxCommision: levelMaxCommision
        }

        if (this.user.rights & this.availablePermissions.CAN_PURCHASES_READ) {
          const levelMargin = (levelPrice - this.product.purchaseListPrice) / levelPrice
          const levelMaxMargin = (levelMaxPrice - this.product.purchaseListPrice) / levelMaxPrice
          level.levelMargin = levelMargin
          level.levelMaxMargin = levelMaxMargin
        }

        levels.push(level)
      }
      // sort (just in case)
      const result = levels.sort(function (a, b) {
        return a.levelId - b.levelId
      })
      return result
    }
  },
  mounted () {
    EventBus.$on('keyF2', (e) => {
      e.preventDefault()
      this.show && this.editProduct()
    })
  },
  beforeDestroy () {
    EventBus.$off('keyF2')
  },
  methods: {
    editProduct () {
      this.$router.push({
        name: 'product',
        params: {
          id: this.product.productId
        }
      })
    },
    cancel () {
      this.show = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
